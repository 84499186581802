import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import ContentArea from "components/ContentArea";
import Section from "components/Section";
import useIsInEditMode from "hooks/useIsInEditMode";
import { addEditAttributes } from "utils/episerver";
import getComponentTypeForContent from "utils/getComponentTypeForContent";

import {
    Container,
    Heading,
    LeftSideCol,
    RightSideCol,
} from "./SideBySide.styled";
import SideBySideProps from "./SideBySideProps";


const SideBySide = ({
    heading,
    sideOneBlocks,
    sideTwoBlocks,
    identifier,
    anchorName,
    theme = "lightgray",
    displayOption = "Left",
    useFullHeight,
    actionTheme,
    contentTheme,

}: SideBySideProps): ReactElement => {
    const isInEditMode = useIsInEditMode();

    return (
        <ThemeProvider
            theme={{
                displayOption: displayOption,
                useFullHeight: useFullHeight,
                blockInBlock: true,
                actionTheme: actionTheme,
                contentTheme: contentTheme
            }}
        >
            <Section theme={theme} alignItems="center">
                <Container key={identifier} id={anchorName}>
                    {(isInEditMode || heading) && (
                        <Heading
                            {...addEditAttributes("Heading")}
                            dangerouslySetInnerHTML={{ __html: heading || "" }}
                        />
                    )}
                    <LeftSideCol>
                        <ContentArea
                            items={displayOption === "Right" ? sideTwoBlocks : sideOneBlocks }
                            componentSelector={getComponentTypeForContent}
                        />
                    </LeftSideCol>
                    <RightSideCol>
                        <ContentArea
                            items={displayOption === "Right" ? sideOneBlocks :sideTwoBlocks }
                            componentSelector={getComponentTypeForContent}
                        />
                    </RightSideCol>
                </Container>
            </Section>
        </ThemeProvider>
    );
};

export default React.memo(SideBySide);
