import { styled, css } from "styled-components";
import theme from "styled-theming";

import { Container as GridContainer } from "style/components/Page";
import { styleHeadingXL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    ${columnGap}
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);

    ${MQ.FROM_L} {
        grid-auto-rows: auto;
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const Heading = styled.h2`
    ${styleHeadingXL};
    grid-column: col-start 1 / span 12;
    margin-bottom: 2.625rem;
    margin-top: 0;

    ${theme("displayOption", {
        Right: css`
            text-align: right;
        `
    })}

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 12;
        margin-bottom: 4.375rem;
        ${theme("displayOption", {
            Right: css`
                grid-column: col-start 2 / span 11;
            `
        })}
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 1 / span 8;
        margin-bottom: 6.125rem;
        ${theme("displayOption", {
            Right: css`
                grid-column: col-start 5 / span 8;
            `
        })}
    }
`;

export const LeftSideCol = styled.div`
    grid-column: col-start 1 / span 12;

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Right: "col-start 5 / span 8",
        })};
    }

    ${theme("displayOption", {
        Right: css`
            order: 1;
        `
    })}

    ${MQ.FROM_L} {
        order:0;
        grid-column: ${theme("displayOption", {
            Right: "col-start 1 / span 5",
            Left: "col-start 1 / span 7"
        })}
    }
    ${MQ.FROM_XL} {
        grid-column: ${theme("displayOption", {
            Right: "col-start 1 / span 4",
            Left: "col-start 1 / span 8"
        })};
    }
    ${theme("useFullHeight", {
        true: css`
        > div {
            height: 100%
        }
        `
    })}
`

export const RightSideCol = styled.div`
    grid-column: col-start 1 / span 12;

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 5 / span 8"
        })};
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("displayOption", {
            Right: "col-start 6 / span 7",
            Left: "col-start 8 / span 5"
        })};
    }
    ${MQ.FROM_XL} {
        grid-column: ${theme("displayOption", {
            Right: "col-start 5 / span 8",
            Left: "col-start 9 / span 4"
        })};
    }
    ${theme("useFullHeight", {
        true: css`
        > div {
            height: 100%
        }
        `
    })}
`
